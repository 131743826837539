import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzImageModule } from 'ng-zorro-antd/image';
import { HasRoleDirective } from './directives/has-role.directive';
import { HasRolePipe } from './pipes/has-role.pipe';
import { GetByFieldPipe } from './pipes/get-by-field.pipe';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ImageHolderComponent } from './components/image-holder/image-holder.component';
import { ImageDesignerComponent } from './components/image-designer/image-designer.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { TruncatePipe } from './pipes/truncate.pipe';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
	declarations: [
		TruncatePipe,
		HasRoleDirective,
		HasRolePipe,
		GetByFieldPipe,
		ImageUploaderComponent,
		ImageHolderComponent,
		ImageDesignerComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		
		NzButtonModule,
		NzIconModule,//.forRoot(icons),
		NzButtonModule,
		NzCardModule,
		NzFormModule,
		NzInputModule,
		NzAlertModule,
		NzListModule,
		NzSelectModule,
		NzCheckboxModule,
		NzSpaceModule,
		NzToolTipModule,
		NzTableModule,
		NzModalModule,
		NzDividerModule,
		NzGridModule,
		NzTagModule,
		NzPaginationModule,
		NzUploadModule,
		NzImageModule,
	
		ColorSketchModule,
		AngularEditorModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		
		NzGridModule,
		NzButtonModule,
		NzIconModule,
		NzButtonModule,
		NzCardModule,
		NzFormModule,
		NzInputModule,
		NzAlertModule,
		NzListModule,
		NzSelectModule,
		NzCheckboxModule,
		NzSpaceModule,
		NzToolTipModule,
		NzTableModule,
		NzModalModule,
		NzDividerModule,
		NzTagModule,
		NzPaginationModule,
		NzUploadModule,
		NzImageModule,


		TruncatePipe,
		HasRolePipe,
		HasRoleDirective,
		GetByFieldPipe,
		ImageUploaderComponent,
		ImageHolderComponent,
		ImageDesignerComponent,

		ColorSketchModule,
		AngularEditorModule,
	]
})
export class SharedModule { }
